import Patient from "../components/Patient.js";
import Counselor from "../components/Counselor.js";
import Organization from "../components/Organization.js";
import Pricing from "../components/Pricing";
import Hero from "../components/Hero";
import Layout from "../components/Layout/Layout";
import SeoHead from "../components/SeoHead";

export default function Home() {
  return (
    <>
      <SeoHead title='Digitaal signaleringsplan' />
      <Layout>
        <Hero />
        <Patient />
        <Counselor />
        <Organization />
        {/* <Pricing /> */}
      </Layout>
    </>
  );
}

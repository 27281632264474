import Image from "next/image";
import React, { useMemo } from "react";
import { motion } from "framer-motion";
import getScrollAnimation from "../utils/getScrollAnimation";
import ScrollAnimationWrapper from "./Layout/ScrollAnimationWrapper";

const features = [
  "Je eigen persoonlijke signalen, foto's en meer",
  "Overzicht van je week",
  "Gratis als patiënt, ook na ontkoppeling",
  //"Personaliseer met eigen vragen, acties, foto's, ...",
  // "Samenwerken met je begeleiders",
  // "Vertrouwde papieren versie afdrukken",
];

const Feature = () => {
  const scrollAnimation = useMemo(() => getScrollAnimation(), []);

  return (
    <div
      className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto"
      id="patient"
    >
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 p  y-8 my-12 content-center	">
        <ScrollAnimationWrapper className="flex w-full order-2 sm:order-1">
          <motion.div className="h-full mx-auto p-4" variants={scrollAnimation}>
            <video
              className="mx-auto"
              style={{
                borderRadius: 15
              }}
              autoPlay
              muted
              loop
              playsInline
              height={2546 / 4}
              width={1175.8 / 4}
              preload="none"
              controls
              layout="responsive"
            >
              <source src="/assets/patient.mp4" type="video/mp4" />
              <Image
                src="/assets/Illustration2.png"
                alt="Screenshot Sipi, berkenen van een fase"
                // layout="intrinsic"
                // layout="responsive"
                quality={100}
                height={2546 / 4}
                width={1175.8 / 4}
              />
            </video>

            {/* <div className="h-max w-auto relative">
              <Image
                src="/assets/Illustration2.png"
                alt="Picture of the author"
                layout="fill" // required
                objectFit="cover" // change to suit your needs
                // className="rounded-full" // just an example
              />
            </div> */}
          </motion.div>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="content-center	order-1 sm:order-2">
          <motion.div
            className="flex flex-col items-end mx-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-black-600">
              Als patiënt de app gebruiken
            </h3>
            {/* <p className="my-2 text-black-500">
              Met de smartphone en web app kan je jouw eigen signaleringsplan
              opnemen in de app (signalen, acties, foto's, ...).
            </p> */}
            <ul className="my-4 text-black-500 self-start list-inside ml-8">
              {features.map((feature, index) => (
                <motion.li
                  className="relative circle-check custom-list"
                  custom={{ duration: 2 + index }}
                  variants={scrollAnimation}
                  key={feature}
                  whileHover={{
                    scale: 1.1,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                >
                  {feature}
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </ScrollAnimationWrapper>
      </div>
      <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 p  y-8 my-12">
        <ScrollAnimationWrapper className="content-center	">
          <motion.div
            className="flex flex-col items-center mx-auto w-full lg:w-9/12"
            variants={scrollAnimation}
          >
            <h4 className="text-2xl lg:text-2xl font-medium leading-relaxed text-black-600">
              Koen deelt zijn ervaring in het Open Venster magazine
            </h4>
            <p className="my-2 text-black-500 italic">
              Door Sipi sta ik sowieso{" "}
              <span className="highlight">
                bewuster stil bij hoe ik me voel
              </span>{" "}
              en onderneem ik ook{" "}
              <span className="highlight">sneller actie</span>. Soms verkeer je
              nog in staat van ontkenning en dan helpt het om{" "}
              <span className="highlight">
                zwart op wit je scores te zien staan.
              </span>{" "}
              Verpleegkundige Annemie kan met de begeleiderskant van de app mijn
              scores vanop afstand volgen. Af en toe brengt ze dit{" "}
              <span className="highlight"> ter sprake tijdens een overleg</span>{" "}
              en blikken we samen terug op de afgelopen periode.
            </p>
            <a
              className="my-2 text-black-500"
              href="https://www.flipsnack.com/CABE9699E8C/2024-open-venster-58/full-view.html?p=6"
              target="_blank"
            >
              Lees meer
            </a>
          </motion.div>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="flex w-full justify-end">
          <motion.div className="h-full mx-auto p-4" variants={scrollAnimation}>
            <Image
              src="/assets/Illustration3.png"
              alt="Screenshot Sipi, overzicht van de scores"
              // layout="intrinsic"
              // layout="responsive"
              quality={100}
              height={2546 / 4}
              width={1175.8 / 4}
            />
          </motion.div>
        </ScrollAnimationWrapper>
      </div>
    </div>
  );
};

export default Feature;

import React from "react";

const ButtonPrimary = ({ children, onClick, href, addClass }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } // Call the onClick function if it is passed
    else {
      if (href === undefined) return;
      window.location.href = href; // Redirect the user to the specified URL
    }
  };
  return (
    <button
      onClick={handleClick}
      className={
        "py-3 lg:py-4 px-12 lg:px-16 text-white-500 font-semibold rounded-lg bg-orange-500 hover:shadow-orange-md transition-all outline-none " +
        addClass
      }
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
